window.addEventListener('DOMContentLoaded', () => {
  scrollEffect();
}, false);

const scrollEffect = () => {
  // 円の変数
  let flag = false;
  const circles = document.getElementsByClassName('circle');
  const scrollTopDefault = window.pageYOffset || document.documentElement.scrollTop;

  // タイトル等の変数
  const contentsContentTitles = document.getElementsByClassName('contents-content-title');
  const contentsContentTitleSubs = document.getElementsByClassName('contents-content-title-sub');
  const contentsContentImg = document.getElementsByClassName('contents-content-img');
  const wH = window.innerHeight;
  let alpha;

  function circleSetPosition() {
    if (!flag) {
      requestAnimationFrame(() => {
        flag = false;
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const wW = window.innerWidth;

        // 円
        for (let i = 0, l = circles.length; i < l; i++) {
          if (circles[i].dataset.direction === 'down') {
            circles[i].style.top = Number(circles[i].dataset.defaltPosition) - ((scrollTopDefault - scrollTop) * .7) + 'px';
          } else if (circles[i].dataset.direction === 'up') {
            circles[i].style.top = Number(circles[i].dataset.defaltPosition) + ((scrollTopDefault - scrollTop) * .7) + 'px';
          }
        }

        // タイトル等の変数
        for (let i = 0, l = contentsContentTitles.length; i < l; i++) {
          // 要素の上端が画面に入ったら（正確にはプラスアルファ）
          if (wW < 768) {
            alpha = 0;
          } else {
            alpha = 80;
          }

          if (wH > (contentsContentTitles[i].getBoundingClientRect().top + alpha)) {
            contentsContentTitles[i].classList.add('contents-content-title-active');
            contentsContentTitleSubs[i].classList.add('contents-content-title-sub-active');
            contentsContentImg[i].classList.add('contents-content-img-active');
          }
        }
      });

      flag = true;
    }
  }

  document.addEventListener('scroll', circleSetPosition, { passive: true });
};